import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalRootComponent from "./modals/ModalRootComponent";
import { faBoltLightning, faCogs, faDoorClosed, faGifts, faHamburger, faHomeAlt, faInfo, faInfoCircle, faLink, faMoneyBill, faMoneyBillWave, faNavicon, faPercentage, faPlugCirclePlus, faPlusCircle, faSignOutAlt, faSlash, faSpinner, faUserFriends, faUserGroup } from "@fortawesome/free-solid-svg-icons";

import { motion } from "framer-motion";
import { useContext } from "react";
import { AccountContext } from "../context/AccountContext";

let SidebarComponent = ({ handleSwitch , activeTab, setSidebarActive}) => {
    let {fullName} = useContext(AccountContext);
    return <>
        <motion.div
            onClick={() => setSidebarActive(false)}
            className="z-20 flex flex-row absolute justify-start w-screen h-screen bg-rouzo-overlay "
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.1 }}
        >
            <div className="flex-col  md:hidden bg-rouzo-deep-blue h-screen pt-10">
                <div className="flex pl-8 gap-4 flex-col">
                    <div>
                        <p className="text-white font-regular text-xs text-left">Hi, <br></br><span className="font-bold text-sm">{fullName ? fullName : ""}</span></p>
                    </div>

                    <div onClick={() => handleSwitch("home")} className={`flex flex-row items-center gap-2 p-2 pr-4 ${activeTab == "home" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faHomeAlt}></FontAwesomeIcon>
                        <p>Home</p>
                    </div>
                    <div onClick={() => handleSwitch("wallet")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "wallet" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faMoneyBillWave}></FontAwesomeIcon>
                        <p>Wallet</p>
                    </div>
                    <div onClick={() => handleSwitch("customers")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "customers" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faUserFriends}></FontAwesomeIcon>
                        <p>Customers</p>
                    </div>
                    <div onClick={() => handleSwitch("paymentLinks")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "paymentLinks" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                        <p>Payment Links</p>
                    </div>
                    <div onClick={() => handleSwitch("discountCodes")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "discountCodes" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                        <p>Discount Codes</p>
                    </div>

                    <div onClick={() => handleSwitch("transactions")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "transactions" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faMoneyBill}></FontAwesomeIcon>
                        <p>Transactions</p>
                    </div>

                    <div onClick={() => handleSwitch("settings")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "settings" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faCogs}></FontAwesomeIcon>
                        <p>Settings</p>
                    </div>

                    <div className={`flex flex-row items-center bottom-1  gap-2 p-2 pr-4 ${activeTab == "" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faDoorClosed}></FontAwesomeIcon>
                        <p>Logout</p>
                    </div>

                </div>
            </div>

        </motion.div>
    </>
}


export default SidebarComponent;