import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../context/AccountContext";
import UserService from "../api/services/UserService";
import { toast } from "react-toastify";
import AccountService from "../api/services/AccountService";
import TransactionService from "../api/services/TransactionService";
import PaymentLinkService from "../api/services/PaymentLinkService";
import DiscountCodeService from "../api/services/DiscountCodeService";

const useAccounts = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isAborted, setIsAborted] = useState(false);

    const [error, setError] = useState(null);

    const { userId, setUserId } = useContext(AccountContext);
    const { user, setUser } = useContext(AccountContext);
    const { fullName, setFullName } = useContext(AccountContext);
    const { paymentLinks, setPaymentLinks } = useContext(AccountContext);
    const { investmentHistory, setInvestmentHistory } = useContext(AccountContext);
    const { stashBalance, setStashBalance } = useContext(AccountContext);
    const { savingsBalance, setSavingsBalance } = useContext(AccountContext);
    const { transactions, setTransactions } = useContext(AccountContext);
    const { transfers, setTransfers } = useContext(AccountContext);
    const { miniTransactions,setMiniTransactions } = useContext(AccountContext);
    const { wallet, setWallet } = useContext(AccountContext);
    const { savings, setSavings } = useContext(AccountContext);
    const { settings, setSettings } = useContext(AccountContext);
    const { isStash, setIsStash } = useContext(AccountContext);
    const { banks, setBanks } = useContext(AccountContext)
    const { referralSlug, setReferralSlug } = useContext(AccountContext);
    const {customers, setCustomers} = useContext(AccountContext)
    const {discountCodes, setDiscountCodes} = useContext(AccountContext);
   
    const token = localStorage.getItem("rouzo_boost_token");
    //should for fetch

    const fetchUserBanks = async () => {
        try {
            const res = await UserService.fetchUserBanks(token);
            const { type, errors, success } = res;
            console.log(res.data)
            if (success) {
                setBanks(res.data)
            }
        }
        catch (e) {
            console.log(e)
            if (e.response && e.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(e, "error");
                setIsLoading(false);
            }
        }
    }
    const addBank = async (accountNumber, bankCode, bankName) => {
        try {
            const res = await UserService.addBank(accountNumber, bankCode, bankName);
            const { type, errors, success } = res;
            //console.log(res);
            if (success) {
                toast.success("Successfully added your bank")
                fetchUserBanks()
            }
            else {
                toast.error(`Error adding bank \n ${res.msg}`)
            }
            return res
        }
        catch (e) {
            console.log(e)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                // console.log(error, "error");
                setIsLoading(false);
            }
        }
    }

    const fetchSettings = async () => {
        try {
            const res = await AccountService.fetchSettings();
            const { type, errors, success } = res;
            if (success) {
                let { data } = res;
                console.log(res.data);
                setSettings(data);
                return res;
            }
            if (!success) {
                let { statusCode, msg } = res;
                toast.error(msg)
            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
            return res;
        } catch (error) {
            setIsLoading(false);
            //##TODO: ERROR TOAST
            //errorToast(error.message);
            console.log(error)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }
    const saveSettings = async (settings) => {
        try {
            const res = await AccountService.saveSettings(settings);
            const { type, errors, success } = res;
            if (success) {
                let { data } = res;
                console.log(res.data);
                toast.success("Settings updated successfully");
                return res;
            }
            if (!success) {
                let { statusCode, msg } = res;
                toast.error(msg);
                return res;
            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        } catch (error) {
            setIsLoading(false);
            //##TODO: ERROR TOAST
            //errorToast(error.message);
            console.log(error)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }
    const newSettings = async (settings) => {
        try {
            const res = await AccountService.newSettings(settings);
            const { type, errors, success } = res;
            if (success) {
                let { data } = res;
                toast.success("Settings created successfully");
                console.log(res.data);
                return res;
            }
            if (!success) {
                let { statusCode, msg } = res;
                toast.error(msg);
                return res;
            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        } catch (error) {
            setIsLoading(false);
         
            console.log(error)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }
    const fetchProfile = async () => {
        try {
            const res = await UserService.fetchProfile(token);
            const { type, errors, success } = res;
            if (success) {
                let { data } = res;
                console.log(res.data);
                setUser(data);
                setUserId(data.userId)
                setFullName(data.fullName);
                setWallet(data.wallet);
                setStashBalance(data.wallet.balance.stash);

                //setTransactions(data.transactions);
                //setPaymentLinks(data.paymentLinks);
               // setCustomers(data.customers)
                
                //setBanks(data.banks)

                setUserId(data.userId)
                console.log("Few TRans: ",data.fewTransactions);
                let reversed = await data.fewTransactions ? await data.fewTransactions.reverse() : null
                setMiniTransactions(reversed)
                setReferralSlug(data.referralSlug);


            }
            if (!success) {
                let { statusCode, msg } = res;
                if (statusCode === 205) {
                    //##TODO ALERT
                    //navigate("/onboard");
                }
                else {
                    console.log(msg);
                    alert(msg);
                }
            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        } catch (error) {
            setIsLoading(false);
            //##TODO: ERROR TOAST
            //errorToast(error.message);
            console.log(error)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }
    const fetchPaymentLinks = async () => {
        try {
            let res = await PaymentLinkService.fetchPaymentLinks();
            const { type, errors, success } = res;
            console.log(res);
            if (success) {
                toast.success("Successfully fetched payment links")
                setPaymentLinks(res.data)
            }
            else {
                toast.error("Error fetching links")
            }

        }
        catch (e) {
            console.log(e)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }

    const fetchTransactions = async () => {
        try {
            const res = await TransactionService.fetchTransactions();
            const { type, errors, success } = res;
            console.log(res);
            if (success) {
                toast.success("Successfully fetched transaction")
                setTransactions(res.data)
            }
            else {
                toast.error("Error fetching transactions")
            }
        }
        catch (e) {
            console.log(e)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }
    const fetchDiscountCodes = async () => {
        try {
            const res = await DiscountCodeService.fetchDiscountCodes();
            const { type, errors, success } = res;
            console.log(res);
            if (success) {
                toast.success("Successfully fetched discount codes")
                setDiscountCodes(res.data)
            }
            else {
                toast.error("Error fetching discount codes")
            }
        }
        catch (e) {
            console.log(e)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }

    const fetchCustomers = async () => {
        try {
            const res = await AccountService.fetchCustomers();
            const { type, errors, success } = res;
            console.log(res);
            if (success) {
                toast.success("Successfully fetched customers")
                setCustomers(res.data)
            }
            else {
                toast.error("Error fetching transactions")
            }
        }
        catch (e) {
            console.log(e)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }

    const updateAccount = async (data) => {
        try {
            const res = await AccountService.updateAccount(data);
            const { type, errors, status } = res;
            if (status === "success") {
                let { data } = res;
                //console.log(res.data.investor);
                fetchProfile()
                toast.success(res.message ? res.message : res.mg);
            }
            if (status === "error") {
                let { statusCode, msg, message } = res;


                console.log(msg);
                toast.error(msg ? msg : message);

            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        }
        catch (e) {
            console.log(e);

            if (e.response && e.response.status == 401) {
                toast.error("Session expired. Please do login");
                return setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            return toast.error(e.message ? e.message : e)
        }
    }
    useEffect(() => {
        return () => {
            setIsAborted(true);
        };
    }, []);

    return {
        isLoading,
        error,
        fetchProfile,
        updateAccount,
        fetchPaymentLinks,
        fetchTransactions,
        addBank,
        fetchUserBanks,
        fetchSettings,
        saveSettings,
        newSettings,
        fetchDiscountCodes,
        discountCodes,setDiscountCodes,
        banks, setBanks,
        user, setUser,
        fullName, setFullName,
        stashBalance, setStashBalance,
        transactions, setTransactions,
        transfers, setTransfers,
        savings, setSavings,
        isStash, setIsStash,
        referralSlug, setReferralSlug,
        wallet, setWallet,
        customers,setCustomers,
        fetchCustomers,
        settings, setSettings,
        
        //banks
    };
};

export default useAccounts;
