import axios from "axios";
let development = false;
//layi
export default axios.create({
   //baseURL: "https://staging.api.rouzo.co/api",//process.env.BASE_URL\
   baseURL : development ? "http://127.0.0.1:7038/api" : "https://rouzo-boost-backend-staging.onrender.com/api",
   headers: {
      "Content-Type": "application/json"
   },
   withCredentials : false,
   
})