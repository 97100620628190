import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBarComponent from "../components/NavBarComponent";
import { faBoltLightning, faClose, faCog, faCogs, faDoorClosed, faGifts, faHamburger, faHomeAlt, faInfo, faInfoCircle, faLink, faMoneyBill, faMoneyBillWave, faNavicon, faPercentage, faPlugCirclePlus, faPlusCircle, faSignOutAlt, faSlash, faSpinner, faUserFriends, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useContext } from "react";
import wave1 from "../assets/img/waves-001.jpg"
import wave2 from "../assets/img/wave2.svg";
import wave3 from "../assets/img/wave3.svg";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import InputComponent from "../components/InputComponent";
import SelectComponent3 from "../components/SelectComponent3";

import SelectComponent2 from "../components/SelectComponent2";
import { toast } from "react-toastify";
import UserService from "../api/services/UserService";
import { Link, useNavigate } from "react-router-dom";
import useAccounts from "../hooks/useAccount";
import { AccountContext } from "../context/AccountContext";
import WithdrawalComponent from "../components/modals/WithdrawalComponent";
import NewPaymentLinkComponent from "../components/modals/NewPaymentLinkComponent";
import PaymentLinkListComponent from "../components/PaymentLinkListComponent";
import TransactionListComponent from "../components/TransactionListComponent";
import SettingsPage from "./settings/SettingsPage";
import Constants from "../constants/Constants";
import NewDiscountCodeComponent from "../components/modals/NewDiscountCodeComponent";
import DiscountCodeListComponent from "../components/DiscountCodeListComponent";
import { faSlackHash } from "@fortawesome/free-brands-svg-icons";
import SidebarComponent from "../components/SidebarComponent";


let DashboardPage = () => {

    let [profileFetched, setProfileFetched] = useState(false);
    let { fullName, miniTransactions, paymentLinks, user, wallet, discountCodes, transactions, customers, settings } = useContext(AccountContext);

    let [isLoading, setIsLoading] = useState(false);

    let [isWithdrawalModalActive, setWithdrawalModalActive] = useState(false);
    let [isNewPaymentLinkModalActive, setNewPaymentLinkModalActive] = useState(false);
    let [isNewDiscountCodeModalActive, setNewDiscountCodeModalActive] = useState(false);
    let [isSidebarActive, setSidebarActive] = useState(false);

    const navigate = useNavigate();
    let { fetchDiscountCodes, fetchPaymentLinks, fetchProfile, fetchUserBanks, addBank, fetchSettings, fetchTransactions, fetchCustomers } = useAccounts();
    let { banks, setBanks } = useContext(AccountContext);
    let [isLoadingAddingAccount, setIsLoadingAddingAccount] = useState(false);
    let [loadedUserBanks, setLoadedUserBanks] = useState(false);
    let [accountNumber, setAccountNumber] = useState(null);
    let [bankCode, setBankCode] = useState(null);
    let [bankName, setBankName] = useState(null);
    let [accountName, setAccountName] = useState(null);




    let [bankList, setBankList] = useState(false);
    let [isAccountResolving, setIsAccountResolving] = useState(false);
    let [activeTab, setActiveTab] = useState("home")

    //let [isAccountResolving, setIsAccountResolving] = useState(false);
    let fetchBankList = async () => {
        let b = await UserService.fetchBankList();

        console.log(b);

        setBankList(b.data);
    }

    let handleChangeAccountNumber = async (e) => {
        console.log("account number changed", e)
        setAccountNumber(e.target.value);
        setAccountName(null);
        // resolveAccount()
    }

    let handleChangeBank = async (e) => {
        console.log("bank changed")
        setAccountName(null);
        setBankCode(e);
        let bName = (bankList.filter(o => o.code == e))[0].name;
        console.log(bName);

        setBankName(bName)
        //resolveAccount();
    }

    let handleAddAccount = async () => {
        console.log("click add")
        if (isLoadingAddingAccount) {
            return

        }

        if (accountName) {
            setIsLoadingAddingAccount(true);

            try {
                let add = await addBank(accountNumber, bankCode, bankName);
            }
            catch (e) {
                toast.error("Account not added.")
                console.log(e)
                setIsLoadingAddingAccount(false)
            }
        }
        else {
            toast.error("Account not resolved.")
        }



        setIsLoadingAddingAccount(false);
    }

    let handleProfileFetch = async () => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        if (!customers) {
            fetchCustomers();
        }
        if (!transactions) {
            fetchTransactions();
        }
        if (!user.fullName) {
            fetchProfile()
        }
        if (!loadedUserBanks) {
            fetchUserBanks();
            setLoadedUserBanks(true);
        }
        if (paymentLinks == null) {
            fetchPaymentLinks();

        }
        if (!settings) {
            fetchSettings()
        }
        if (!discountCodes) {
            fetchDiscountCodes()
        }
        setProfileFetched(true)
    }



    let handleWithdrawalModal = async () => {
        if (banks) {
            if (banks.length > 0) {
                setWithdrawalModalActive(true)
            }
            else {
                toast.error("Withdarawl account has not been set");
            }
        }
        else {
            toast.error("Withdarawl account has not been set");
        }

    }
    useEffect(() => {


        if (!profileFetched) {
            handleProfileFetch()
        }
        /*if (banks) {
            if (banks.length > 0) {
                toast.success("Withdrawal account exist")
                navigate("/dashboard")
            }
        }
        else {
            console.log("User does not have an account")
        }*/
        if (bankCode) {
            console.log("BankCode : ", bankCode);
            if (accountNumber) {
                if (accountNumber.length >= 10) {
                    if (accountName) {
                        return
                    }
                    if (isAccountResolving) {
                        return
                    }
                    setIsAccountResolving(true);
                    let res = async () => {


                        let resolve = await UserService.resolveAccount(accountNumber, bankCode, bankName);
                        console.log(resolve);

                        if (resolve.success) {
                            //setBankCode(null)
                            //setAccountNumber(null)
                            setAccountName(resolve.data.account_name);
                            setIsAccountResolving(false)
                        }
                        else {
                            //setBankCode(null)
                            //setAccountNumber(null)
                            toast.error(resolve.msg);
                            setIsAccountResolving(false)
                        }
                    }

                    res()
                }

            }
            else {
                console.log("noin")
            }
        }
        if (!bankList) {
            fetchBankList()
        }



    })

    let handleSwitch = (switchTo) => {
        setActiveTab(switchTo);
    }
    return <div>
        {isWithdrawalModalActive ? <WithdrawalComponent setIsModalActive={setWithdrawalModalActive} ></WithdrawalComponent> : ""}
        {isNewPaymentLinkModalActive ? <NewPaymentLinkComponent setIsModalActive={setNewPaymentLinkModalActive} ></NewPaymentLinkComponent> : ""}
        {isNewDiscountCodeModalActive ? <NewDiscountCodeComponent setIsModalActive={setNewDiscountCodeModalActive}></NewDiscountCodeComponent> : ""}
        {isSidebarActive ? <SidebarComponent setSidebarActive={setSidebarActive} activeTab={activeTab} handleSwitch={handleSwitch}></SidebarComponent> : ""}
        <div className="md:hidden top-0 p-4 static w-screen font-bold flex flex-row gap-2 shadow-sm shadow-slate-200/70 justify-end">
            <FontAwesomeIcon className="text-rouzo-base-color" onClick={() => handleSwitch("settings")} icon={faCog}></FontAwesomeIcon>
            {
                !isSidebarActive ?
                    <FontAwesomeIcon className="text-rouzo-base-color" onClick={() => setSidebarActive(true)} icon={faNavicon}></FontAwesomeIcon>
                    :
                    <FontAwesomeIcon className="text-rouzo-base-color" onClick={() => setSidebarActive(false)} icon={faClose}></FontAwesomeIcon>
            }

        </div>

        <div className="flex flex-col  md:flex-row">
            <div className="md:flex hidden flex-col bg-rouzo-deep-blue h-screen pt-10">
                <div className="flex pl-8 gap-4 flex-col">
                    <div>
                        <p className="text-white font-regular text-xs text-left">Hi, <br></br><span className="font-bold text-sm">{fullName ? fullName : ""}</span></p>
                    </div>
                    <div onClick={() => handleSwitch("home")} className={`flex flex-row items-center gap-2 p-2 pr-4 ${activeTab == "home" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faHomeAlt}></FontAwesomeIcon>
                        <p>Home</p>
                    </div>
                    <div onClick={() => handleSwitch("wallet")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "wallet" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faMoneyBillWave}></FontAwesomeIcon>
                        <p>Wallet</p>
                    </div>
                    <div onClick={() => handleSwitch("customers")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "customers" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faUserFriends}></FontAwesomeIcon>
                        <p>Customers</p>
                    </div>
                    <div onClick={() => handleSwitch("paymentLinks")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "paymentLinks" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                        <p>Payment Links</p>
                    </div>
                    <div onClick={() => handleSwitch("discountCodes")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "discountCodes" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                        <p>Discount Codes</p>
                    </div>

                    <div onClick={() => handleSwitch("transactions")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "transactions" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faMoneyBill}></FontAwesomeIcon>
                        <p>Transactions</p>
                    </div>

                    <div onClick={() => handleSwitch("settings")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "settings" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faCogs}></FontAwesomeIcon>
                        <p>Settings</p>
                    </div>

                    <div className={`flex flex-row items-center bottom-1  gap-2 p-2 pr-4 ${activeTab == "" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-white font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faDoorClosed}></FontAwesomeIcon>
                        <p>Logout</p>
                    </div>

                </div>
            </div>


            {/*Dashboard Home*/}
            {activeTab == "home" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <div className="md:hidden " >
                        <p className="font-bold text-md text-left">Explore</p>
                        <div className="overflow-x-scroll w-80 flex flex-row  gap-4">
                            <div style={{ background: `url('${wave2}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "left" }} className="flex min-w-[380px] rounded-md p-4 shadow-xl shadow-slate-200/50">
                                <div className="bg-slate-50/30 rounded-md flex flex-col gap-4 p-2 backdrop-blur-sm ">
                                    <div className="flex flex-row items-center gap-4">
                                        <FontAwesomeIcon className="text-emerald-400 h-8 " icon={faGifts}></FontAwesomeIcon>
                                        <p className="text-rouzo-base-color font-bold text-right">Reward loyal customers and <br></br> keep them asking for more!!</p>
                                    </div>
                                    <div className="flex-row flex justify-end gap-2">

                                        <p onClick={() => handleSwitch("settings")} className="hover:bg-slate-50 hover:text-rouzo-base-color hover:shadow-xl hover:shadow-black/20  text-xs bg-blue-600 rounded-md font-bold text-white p-2 ">Setup Loyalty Rewards</p>


                                        <p onClick={() => handleSwitch("discountCodes")} className="hover:bg-slate-50 hover:text-rouzo-base-color hover:shadow-xl hover:shadow-black/20 text-xs bg-rouzo-green-light rounded-md font-bold text-white  bg-blue-600 p-2 ">Generate Discount Coupons</p>

                                    </div>

                                </div>

                            </div>
                            <div style={{ background: `url('${wave3}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="flex  min-w-[300px] rounded-md p-4 shadow-xl shadow-slate-200/50">
                                <div className="bg-slate-50/50 rounded-md flex flex-col justify-center gap-4 p-2 backdrop-blur-sm ">
                                    <div className="flex flex-row items-center w-full gap-4">
                                        <FontAwesomeIcon className="text-emerald-400 h-8 " icon={faBoltLightning}></FontAwesomeIcon>
                                        <p className="text-rouzo-green-light font-bold text-left">Effortless payment links</p>
                                        <FontAwesomeIcon className="text-emerald-400 h-8 " icon={faBoltLightning}></FontAwesomeIcon>
                                    </div>
                                    <div className="flex-row flex justify-end gap-2">

                                        <p onClick={() => handleSwitch("paymentLinks")} className="hover:bg-slate-50 hover:text-rouzo-green-light items-center hover:shadow-xl hover:shadow-black/20  text-xs bg-rouzo-green-light rounded-md font-bold text-white p-2 flex flex-row gap-2 ">
                                            <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                                            Create a payment link
                                        </p>


                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4">

                        <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="rounded-md flex min-w-40 flex-col">
                            <div className="p-2 flex flex-col gap-2">
                                <p className="font-bold text-xs text-white text-left ">Wallet Balance</p>
                                <div className="flex flex-row text-right gap-1 items-end">
                                    <div className="grow">
                                    </div>
                                    <p className="text-sm font-regular text-rouzo-deep-blue ">₦</p><p className="text-4xl font-bold text-white">{wallet ? wallet.balance ? wallet.balance.stash : 0 : 0}</p>
                                </div>

                            </div>
                            <div className="text-rouzo-deep-blue underline underline-offset-1 flex flex-row ">

                                <div className="grow">
                                </div>
                                <p onClick={() => handleSwitch("wallet")} className="bg-white px-2 text-right text-xs font-bold hover:cursor-pointer rounded-l-md  ">View wallet</p>

                            </div>
                        </div>

                        <div className="md grow flex flex-row p-4  rounded-sm  gap-4 text-center items-center justify-center outline outline-1 outline-rouzo-deep-blue">
                            <div className="flex flex-col">
                                <div className="text-3xl font-bold">
                                    {customers ? customers.length : 0}
                                </div>
                                <div className="text-sm">Customers acquired</div>
                            </div>
                            <div className="flex flex-col">
                                <div className="text-3xl font-bold">
                                    {transactions ? transactions.length : 0}
                                </div>
                                <div className="text-sm">Total transactions</div>
                            </div>

                            <div className="flex flex-col">
                                <div className="text-3xl">
                                    NGN 0
                                </div>
                                <div className="text-sm">Transactions performed</div>
                            </div>
                            <div>

                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col gap-2">
                        <p className="font-regular text-xl text-left">Loyalty points</p>
                        <div className="flex flex-row gap-3">

                            <div className="flex flex-row gap-2 ">
                                <div className="flex flex-col gap-6 rounded-md p-2 bg-emerald-300">
                                    <p className="text-emerald-800 text-xs">Loyalty points issued</p>
                                    <p className="font-bold text-xl">{discountCodes ? discountCodes.length : 0}</p>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2 ">
                                <div className="flex flex-col gap-6 rounded-md p-2 bg-green-300">
                                    <p className="text-green-800 text-xs">Loyalty points Used</p>
                                    <p className="font-bold text-xl">{discountCodes ? discountCodes.length : 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className="font-regular text-xl text-left">Discount Giveaway</p>
                        <div className="flex flex-row gap-3">

                            <div className="flex flex-row gap-2 ">
                                <div className="flex flex-col gap-6 rounded-md p-2 bg-emerald-300">
                                    <p className="text-emerald-800 text-xs">Discount Code Generated</p>
                                    <p className="font-bold text-xl">{discountCodes ? discountCodes.length : 0}</p>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2 ">
                                <div className="flex flex-col gap-6 rounded-md p-2 bg-green-300">
                                    <p className="text-green-800 text-xs">Discount Code Used</p>
                                    <p className="font-bold text-xl">{discountCodes ? discountCodes.length : 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2">
                        <p className="font-regular text-xl text-left">Recent Transactions</p>
                        {miniTransactions ?
                            <TransactionListComponent data={miniTransactions}></TransactionListComponent> :
                            <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="rounded-md">
                                <div className="bg-white p-4 bg-opacity-90">
                                    <div className="flex  text-center flex-col rounded-md items-center gap-4">
                                        <div className="flex flex-col items-center gap-2">
                                            <FontAwesomeIcon className="text-3xl text-rouzo-blue-gray-white" icon={faMoneyBill}></FontAwesomeIcon>
                                            <p className="text-sm text-rouzo-text-color-2 font-bold">No transactions yet</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>


                    {/*<div className="flex flex-col gap-2">
                        <p className="text-left text-black font-bold text-xl">Customers</p>
                        <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="rounded-md">
                            <div className="bg-white p-4 bg-opacity-90">
                                <div className="flex  text-center flex-col rounded-md items-center gap-4">
                                    <div className="flex flex-col items-center gap-2">
                                        <FontAwesomeIcon className="text-3xl text-rouzo-blue-gray-white" icon={faUserGroup}></FontAwesomeIcon>
                                        <p className="text-sm text-rouzo-text-color-2 font-bold">No customers yet</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>*/}
                </div>
                : ""
            }
            {/*Dashboard Wallet*/}
            {activeTab == "wallet" ?
                <div className="flex gap-4 flex-col grow p-4  pt-2 md:pt-10">
                    <div className="flex flex-col gap-2">
                        <p className="text-left text-black font-bold text-xl">Wallet</p>

                        <div className="flex flex-col justify-center md:flex-row gap-4">
                            <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="rounded-md pb-2 flex min-w-40 flex-col">
                                <div className="p-2 flex flex-col gap-2">
                                    <p className="font-bold text-xs text-white text-left ">Balance</p>
                                    <div className="flex flex-row text-right gap-1 items-end">
                                        <div className="grow">
                                        </div>
                                        <p className="text-sm font-regular text-rouzo-deep-blue ">₦</p><p className="text-4xl font-bold text-white">{wallet ? wallet.balance ? wallet.balance.stash : 0 : 0}</p>
                                    </div>

                                </div>
                                <div className="text-rouzo-deep-blue underline underline-offset-1 flex flex-row ">

                                    <div className="grow">
                                    </div>
                                    <p onClick={() => handleSwitch("transactions")} className="bg-white px-2 text-right text-xs font-bold hover:cursor-pointer rounded-l-md  ">View transactions</p>

                                </div>
                            </div>

                            <div className="flex flex-col items-center justify-around">
                                <p onClick={() => handleWithdrawalModal()} className="flex flex-row gap-2 items-center px-2 py-1 bg-rouzo-base-color rounded-md hover:cursor-pointer">
                                    <FontAwesomeIcon className="text-rouzo-green-light" icon={faArrowAltCircleUp}></FontAwesomeIcon>
                                    <p className="font-bold text-white font-regular ">Withdraw</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className="text-left text-black font-bold text-xl">Withdrawal Account</p>
                        <div>
                            <p className="flex flex-row bg-orange-200 rounded-md px-2 py-1 gap-2 items-center ">
                                <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500"></FontAwesomeIcon>
                                <p className="text-xs text-orange-500">On success, funds transferred to specified withdrawal account. Verify account inputted.</p>
                            </p>
                            <div>
                                {banks && banks.length == 0 ?
                                    <div className="justify-center flex flex-col gap-4 px-6 py-2">
                                        <div className="text-left">
                                            <p>Account Number</p>
                                            <InputComponent onChange={(e) => handleChangeAccountNumber(e)}></InputComponent>
                                        </div>
                                        <div className="text-left">
                                            <p>Bank</p>
                                            {bankList ? <SelectComponent3 className="text-black" data={bankList} onChange={(e) => handleChangeBank(e.target.value)}></SelectComponent3>
                                                :
                                                <SelectComponent2 data={["No banks"]} ></SelectComponent2>}

                                        </div>
                                        <div className="text-left">
                                            <p>Account Name</p>
                                            {
                                                isAccountResolving ?
                                                    <p className="bg-rouzo-blue-light py-2 px-[25px] rounded-xl text-center text-black">
                                                        <FontAwesomeIcon icon={faSpinner} spin={true} className="h-4  text-rouzo-base-color">
                                                        </FontAwesomeIcon>
                                                    </p>
                                                    :
                                                    <p className="bg-rouzo-blue-light py-2 px-[25px] rounded-xl text-black">{accountName ? accountName : ""} </p>
                                            }
                                        </div>
                                        <p onClick={() => handleAddAccount()} className={`px-10 py-2 ${!isLoadingAddingAccount ? "bg-rouzo-deep-blue hover:cursor-pointer" : "bg-rouzo-blue-light hover:cursor-disabled"} text-white rounded-xl text-md justify-center text-center flex flex-row gap-4 `}>
                                            Add Account
                                            {isLoadingAddingAccount ?
                                                <FontAwesomeIcon icon={faSpinner} spin={true} className="h-4  text-white">
                                                </FontAwesomeIcon>
                                                : ""}
                                        </p>
                                    </div>
                                    :
                                    <div className="justify-center flex flex-col gap-4 px-6 py-2">
                                        <div className="text-left">
                                            <p>Account Number</p>
                                            <InputComponent value={banks && banks.length > 0 ? banks[0].accountNumber : ""}></InputComponent>
                                        </div>
                                        <div className="text-left">
                                            <p>Bank</p>
                                            {bankList ? <SelectComponent3 className="text-black" data={[{ name: banks[0].bankName }]} ></SelectComponent3>
                                                :
                                                <SelectComponent2 data={["No banks"]} ></SelectComponent2>}

                                        </div>
                                        <div className="text-left">
                                            <p>Account Name</p>
                                            {
                                                isAccountResolving ?
                                                    <p className="bg-rouzo-blue-light py-2 px-[25px] rounded-xl text-center text-black">
                                                        <FontAwesomeIcon icon={faSpinner} spin={true} className="h-4  text-rouzo-base-color">
                                                        </FontAwesomeIcon>
                                                    </p>
                                                    :
                                                    <p className="bg-rouzo-blue-light py-2 px-[25px] rounded-xl text-black">{banks && banks.length > 0 ? banks[0].accountName.toUpperCase() : "ee"} </p>
                                            }
                                        </div>
                                        {/*<p onClick={() => handleAddAccount()} className={`px-10 py-2 ${!isLoadingAddingAccount ? "bg-rouzo-deep-blue hover:cursor-pointer" : "bg-rouzo-blue-light hover:cursor-disabled"} text-white rounded-xl text-md justify-center text-center flex flex-row gap-4 `}>
                                            Add Account
                                            {isLoadingAddingAccount ?
                                                <FontAwesomeIcon icon={faSpinner} spin={true} className="h-4  text-white">
                                                </FontAwesomeIcon>
                                                : ""}
                                        </p>*/}
                                    </div>}

                            </div>
                        </div>
                    </div>
                </div>
                : ""
            }
            {/*Dashboard Customers*/}
            {activeTab == "customers" ?
                <div className="flex gap-4 flex-col grow p-4  pt-2 md:pt-10 ">
                    <div className="flex flex-col gap-2">
                        <p className="text-left text-black font-bold text-xl">Customers</p>
                        <div className="md flex flex-row p-4  rounded-sm  gap-4 text-center items-center justify-center outline outline-1 outline-rouzo-deep-blue">
                            <div className="flex flex-col">
                                <div className="text-3xl">
                                    {customers ? customers.length : 0}
                                </div>
                                <div className="text-sm">Total Customers acquired</div>
                            </div>

                        </div>
                        <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "cover" }} className="p-4 rounded-md">
                            <div className="flex flex-col rounded-md items-center gap-4">
                                <div className="flex flex-row items-center gap-4 ">
                                    <FontAwesomeIcon className="text-3xl text-rouzo-green-light" icon={faUserGroup}></FontAwesomeIcon>
                                    <p className="text-[30px] text-white font-bold flex flex-col items-center gap-2">
                                        Build your customer base
                                        <span className="text-white text-xs">
                                            Never lose track of a customer again.<br></br> RouzoBoost automatically stores customer information associated with each payment link, allowing you to build a valuable customer list.
                                        </span>
                                    </p>
                                    <FontAwesomeIcon className="text-3xl text-rouzo-green-light" icon={faUserFriends}></FontAwesomeIcon>
                                </div>

                            </div>
                        </div>
                        <div>
                            <table className='border-spacing-12 border-collapse' >
                                <tr className='text-left flex gap-4 font-lota p-4 pb-0 items-start font-bold text-xs '>
                                    <td className=''>FullName</td>
                                    <td>EmailAddress</td>
                                    <td>Mobile number </td>
                                </tr>
                                {customers ?
                                    customers.map(customer => {
                                        return <tr className='rounded-md justify-evenly flex flex-row gap-4 p-4 mb-2 items-center bg-slate-100'>
                                            <td className='text-xs flex flex-col gap-1'>
                                                <p className='text-left'>{customer.emailAddress}</p>
                                                <p className='text-[9px] font-bold'>{customer.createdAt ? `${new Date(customer.createdAt).toDateString()} ${new Date(customer.createdAt).toLocaleTimeString()}` : ""}</p>
                                            </td>
                                            <td className='text-rouzo-green-light font-bold text-md'>{customer.emailAddress}</td>
                                            <td>
                                                <p className='text-rouzo-green-light font-bold text-md'>{customer.mobileNumber}</p>

                                            </td>

                                        </tr>
                                    })
                                    :
                                    <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="rounded-md">
                                        <div className="bg-white p-4 bg-opacity-90">
                                            <div className="flex  text-center flex-col rounded-md items-center gap-4">
                                                <div className="flex flex-col items-center gap-2">
                                                    <FontAwesomeIcon className="text-3xl text-rouzo-blue-gray-white" icon={faUserGroup}></FontAwesomeIcon>
                                                    <p className="text-sm text-rouzo-text-color-2 font-bold">No customers yet</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>}
                            </table>
                        </div>


                    </div>
                </div>
                : ""
            }
            {activeTab == "transactions" ?
                <div className="flex gap-4 flex-col grow p-4  pt-2 md:pt-10">
                    <div className="flex flex-col gap-2">
                        <p className="text-left text-black font-bold text-xl">Transactions</p>
                        {transactions ?
                            <TransactionListComponent data={transactions}></TransactionListComponent> :
                            <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="rounded-md">
                                <div className="bg-white p-4 bg-opacity-90">
                                    <div className="flex  text-center flex-col rounded-md items-center gap-4">
                                        <div className="flex flex-col items-center gap-2">
                                            <FontAwesomeIcon className="text-3xl text-rouzo-blue-gray-white" icon={faMoneyBill}></FontAwesomeIcon>
                                            <p className="text-sm text-rouzo-text-color-2 font-bold">No transactions yet</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>
                : ""
            }
            {activeTab == "paymentLinks" ?
                <div className="flex gap-4 flex-col grow p-4  pt-2 md:pt-10">
                    <div className="flex flex-col gap-2">
                        <p className="text-left text-black font-bold text-xl">Payment Links</p>
                        <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "cover" }} className="p-4 rounded-md">
                            <div className="flex flex-col rounded-md items-center gap-4">
                                <div className="flex flex-row items-center gap-4 ">
                                    <FontAwesomeIcon className="text-3xl text-rouzo-green-light" icon={faBoltLightning}></FontAwesomeIcon>
                                    <p className="text-[30px] text-white font-bold flex flex-col items-center gap-2">Effortless Payment Links<span className="text-white text-xs"> Simply enter the amount and a brief description, and you're ready to go.</span> </p>
                                    <FontAwesomeIcon className="text-3xl text-rouzo-green-light" icon={faBoltLightning}></FontAwesomeIcon>
                                </div>
                                <p onClick={() => setNewPaymentLinkModalActive(true)} className="flex flex-row gap-2 px-2 py-1 bg-rouzo-deep-blue rounded-md items-center hover:cursor-pointer hover:px-3 hover:bg-white">
                                    <FontAwesomeIcon className="text-rouzo-green-light" icon={faPlusCircle}></FontAwesomeIcon>
                                    <p className="text-rouzo-green-light font-bold">Create a payment link</p>
                                </p>

                            </div>


                        </div>
                        <PaymentLinkListComponent></PaymentLinkListComponent>
                    </div>
                </div>
                : ""
            }
            {activeTab == "discountCodes" ?
                <div className="flex gap-4 flex-col grow p-4  pt-2 md:pt-10 ">
                    <div className="flex flex-col gap-2">
                        <p className="text-left text-black font-bold text-xl">Discount Codes</p>
                        <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "cover" }} className="p-4 rounded-md">
                            <div className="flex flex-col rounded-md items-center gap-4">
                                <div className="flex flex-row items-center gap-4 ">
                                    <FontAwesomeIcon className="text-3xl text-rouzo-green-light" icon={faPercentage}></FontAwesomeIcon>
                                    <p className="text-[30px] text-white font-bold flex flex-col items-center gap-2">Giveaway by Discount<span className="text-white text-xs"> Show an heartfeeling appreciation to your loyal customers <br></br>and also spread the love to potential customers by generated a discount code.</span> </p>
                                    <FontAwesomeIcon className="text-3xl text-rouzo-green-light" icon={faSlash}></FontAwesomeIcon>
                                </div>
                                <p onClick={() => setNewDiscountCodeModalActive(true)} className="flex flex-row gap-2 px-2 py-1 bg-rouzo-deep-blue rounded-md items-center hover:cursor-pointer hover:px-3 hover:bg-white">
                                    <FontAwesomeIcon className="text-rouzo-green-light" icon={faPlusCircle}></FontAwesomeIcon>
                                    <p className="text-rouzo-green-light font-bold">Generate a Discount Coupon</p>
                                </p>

                            </div>


                        </div>
                        <DiscountCodeListComponent></DiscountCodeListComponent>
                    </div>
                </div>
                : ""
            }
            {activeTab == "settings" ?
                <div className="flex gap-4 flex-col grow p-4  pt-2 md:pt-10 ">
                    <div className="flex flex-col gap-2">
                        <p className="text-left text-black font-bold text-xl">Settings</p>
                        <SettingsPage></SettingsPage>
                    </div>
                </div>
                : ""
            }
            <div className="md:flex flex-col min-w-96 hidden p-4 pt-10 justify-start">
                <p className="font-bold text-md text-left">Explore</p>
                <div className="flex flex-col gap-4">
                    <div style={{ background: `url('${wave2}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "left" }} className="flex rounded-md p-4 shadow-xl shadow-slate-200/50">
                        <div className="bg-slate-50/30 rounded-md flex flex-col gap-4 p-2 backdrop-blur-sm ">
                            <div className="flex flex-row items-center gap-4">
                                <FontAwesomeIcon className="text-emerald-400 h-8 " icon={faGifts}></FontAwesomeIcon>
                                <p className="text-rouzo-base-color font-bold text-right">Reward loyal customers and <br></br> keep them asking for more!!</p>
                            </div>
                            <div className="flex-row flex justify-end gap-2">

                                <p onClick={() => handleSwitch("settings")} className="hover:bg-slate-50 hover:text-rouzo-base-color hover:shadow-xl hover:shadow-black/20  text-xs bg-blue-600 rounded-md font-bold text-white p-2 ">Setup Loyalty Rewards</p>

                                <p onClick={() => handleSwitch("discountCodes")} className="hover:bg-slate-50 hover:text-rouzo-base-color hover:shadow-xl hover:shadow-black/20 text-xs bg-rouzo-green-light rounded-md font-bold text-white  bg-blue-600 p-2 ">Generate Discount Coupons</p>

                            </div>

                        </div>

                    </div>
                    <div style={{ background: `url('${wave3}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="flex rounded-md p-2 shadow-xl shadow-slate-200/50">
                        <div className="bg-slate-50/50 rounded-md flex flex-col justify-center gap-4 p-2 backdrop-blur-sm ">
                            <div className="flex flex-row items-center w-full gap-4">
                                <FontAwesomeIcon className="text-emerald-400 h-8 " icon={faBoltLightning}></FontAwesomeIcon>
                                <p className="text-rouzo-green-light font-bold text-left">Effortless payment links</p>
                                <FontAwesomeIcon className="text-emerald-400 h-8 " icon={faBoltLightning}></FontAwesomeIcon>
                            </div>
                            <div className="flex-row flex justify-end gap-2">

                                <p onClick={() => handleSwitch("paymentLinks")} className="hover:bg-slate-50 hover:text-rouzo-green-light items-center hover:shadow-xl hover:shadow-black/20  text-xs bg-rouzo-green-light rounded-md font-bold text-white p-2 flex flex-row gap-2 ">
                                    <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                                    Create a payment link
                                </p>

                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    </div>
}


export default DashboardPage;